import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Register from '../views/Register.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/register',
    name: 'PortalLayout',
    component: () => import(/* webpackChunkName: "portal_layout" */ '../layouts/PortalLayout.vue'),
    children: [
      {
        path: 'register',
        name: 'Register',
        component: Register,
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
      },
      {
        path: 'loading',
        name: 'LoadingTree',
        component: () => import(/* webpackChunkName: "loading_tree" */ '../views/LoadingTree.vue'),
      },
      {
        path: 'welcome',
        name: 'Welcome',
        component: () => import(/* webpackChunkName: "welcome" */ '../views/Welcome.vue'),
      },
      {
        path: 'invite',
        redirect: 'register',
        name: 'Invite',
      },
      {
        path: 'review',
        name: 'Review',
        component: () => import(/* webpackChunkName: "review" */ '../views/Review.vue'),
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
