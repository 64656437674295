<template>
  <div class="register-view">
    <Confetti v-show="referredBy" class="register-view__confetti" />
    <BaseCard v-if="hideDiscoveryQuestion" @keydown.enter="registerUser" class="register-view__card">
      <template v-if="!referredBy">
        <h3 class="title mb-2">Welcome to OurForest! 👋</h3>
        <p class="description">Create an account to begin planting trees.</p>
      </template>
      <template v-else>
        <h3 class="title">You're invited to join OurForest!</h3>
        <p class="description">Join today and plant your first 5 trees, for free.</p>
      </template>
      <BaseField :error="formErrors.email">
        <BaseInput placeholder="Email address" :model-value="email" @update:model-value="email = $event"/>
      </BaseField>
      <BaseField :error="passwordError">
        <BaseInput type="password" placeholder="Password" :model-value="password" @update:model-value="password = $event"/>
      </BaseField>
      <BaseButton @click="registerUser" class="main-button" text="Start planting trees"/>
      <p v-if="!referredBy" class="footer-text">Already have an account? <router-link class="base-link" to="login">Log in to OurForest</router-link></p>
      <div v-else class="register-view__card--footer">
        OurForest is the Chrome extension that plants trees when you browse online. 🌲
      </div>
    </BaseCard>
    <BaseCard @keydown.enter="submitDiscoverAnswer" v-if="hideDiscoveryQuestion === false" class="register-view__card register-view__discovery-card">
      <h3 class="title mb-2">How did you hear about us?</h3>
      <label v-for="(option, index) in discoveryOptions" :key="option + index" :for="option" class="register-view__card--radio-option">
        <input v-model="radioOption" name="option" type="radio" :id="option" :value="option">
        <p>{{ option }}</p>
      </label>
      <label for="other" class="register-view__card--radio-option radio__other">
        <div>
          <input v-model="radioOption" name="option" type="radio" id="other" value="Other">
          <p>Other</p>
        </div>
        <BaseInput :model-value="otherReason" @update:model-value="otherReason = $event" v-if="radioOption === 'Other'" placeholder="Please explain"/>
      </label>
      <div class="register-view__discovery-card--actions">
        <BaseButton @click="skipDiscoveryQuestion()" class="secondary" text="Skip"/>
        <BaseButton @click="submitDiscoverAnswer()" text="Continue"/>
      </div>
    </BaseCard>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import BaseCard from '@/components/BaseCard.vue';
import BaseInput from '@/components/BaseInput.vue';
import BaseField from '@/components/BaseField.vue';
import Confetti from '../components/Confetti.vue';
import BaseButton from '@/components/BaseButton.vue';
import Analytics from '@/helpers/analytics';
import { emitLogin } from '@/helpers/helpers';

@Options({
  title: 'Sign Up',
  components: {
    BaseCard,
    BaseInput,
    BaseField,
    BaseButton,
    Confetti,
  },
  data() {
    return {
      email: '',
      password: '',
      formErrors: {
        email: '',
        password1: '',
        password2: '',
      },
      referredBy: '',
      discoveryOptions: ['Reddit', 'Instagram', 'Facebook', 'Through a friend', 'Blog or publication', 'Employer'],
      radioOption: '',
      otherReason: '',
      hideDiscoveryQuestion: true,
      userToken: null,
    }
  },
  methods: {
    registerUser() {
      
      for (const i in this.formErrors) this.formErrors[i] = '';

      const referredBy = () => {
        if (this.referredBy) {
          return this.referredBy;
        }
        else if (window.localStorage.getItem('lastReferrer')) {
          return window.localStorage.getItem('lastReferrer');
        }
        else {
          return '';
        }
      }

      const formdata = new FormData();
      formdata.append("email", this.email);
      formdata.append("password1", this.password);
      formdata.append("password2", this.password);
      formdata.append("referred_by", referredBy());
      formdata.append("source", "EXTENSION");

      fetch("https://ourearth.io/api/auth/register/", {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      }).then((response: any) => {
        if (response.status === 409) {
          this.formErrors.email = 'This email is already in use.';
        }
        else return response.json();
      }).then((result: any) => {
      if (result) {
        if (result.token) {
          Analytics.setUser(result.user);
          Analytics.signUp(result.user.email);
          emitLogin(result);
          this.userToken = result.token;
          const userObj = JSON.stringify({ token: result.token, user: { email: result.user.email }});
          window.localStorage.setItem("user", userObj);
          if (!this.referredBy) {
            this.hideDiscoveryQuestion = false;
          } else {
            window.location.href = 'https://chrome.google.com/webstore/detail/ourforest-%E2%80%94-automatically/cjgphmckkkgnlkbdaellanfoajipefnd';
          }
        }
        else {
          for (const i in result) this.formErrors[i] = result[i][0];
        }
      }
      })
    },
    skipDiscoveryQuestion() {
      this.$router.push({ name: 'LoadingTree' });
    },
    submitDiscoverAnswer() {
      let reason = '';
      if (this.radioOption === 'Other' && this.otherReason !== '') {
        reason = this.otherReason;
      } else {
        reason = this.radioOption;
      }
      const raw = JSON.stringify({ "how_discovered_us": reason });
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Access ${this.userToken}`);

      fetch("https://ourearth.io/api/user/how_discovered_us/", {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      })
      .then(this.$router.push({ name: 'LoadingTree' }))
    },
    shuffleArray(array: any) {
      for (var i = array.length - 1; i > 0; i--) {
          var j = Math.floor(Math.random() * (i + 1));
          var temp = array[i];
          array[i] = array[j];
          array[j] = temp;
      }
      this.discoveryOptions = array;
    }
  },
  computed: {
    passwordError() {
      let error = '';
      if (this.formErrors.password1) error = this.formErrors.password1;
      if (this.formErrors.password2) error = this.formErrors.password2;
      return error;
    }
  },
  mounted() {
    if (this.$route.query.referrer) {
      this.referredBy = this.$route.query.referrer;
      window.localStorage.setItem("lastReferrer", this.$route.query.referrer);
      Analytics.referredBy(this.referredBy);
    }
    this.shuffleArray(this.discoveryOptions);
  }
})
export default class Register extends Vue {

}
</script>

<style lang="scss">
.register-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
}
.register-view__card {
  margin-top: 80px;
  max-width: 400px;
  text-align: center;
}
.register-view__card .main-button {
  margin-top: 24px;
}
.register-view__card .footer-text {
  margin-top: 24px;
  margin-bottom: 0;
}
.register-view__confetti {
  position: absolute;
  top: -80px;
  pointer-events: none;
}
.register-view__confetti .confetti_container--confetti {
  animation-iteration-count: 1;
}
.register-view__card--footer {
  padding-top: 16px;
  border-top: 1px solid #DEE1E6;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: -24px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
  background: #fafafa;
  text-align: left;
  margin-top: 32px;
}
.register-view__card--radio-option {
  display: flex;
  align-items: center;
  border-top: 1px solid #F2F2F2;
  padding: 16px 4px;
  background: white;
  cursor: pointer;
  transition: ease 0.1s;
}
.register-view__card--radio-option:last-of-type {
  border-bottom: 1px solid #F2F2F2;
}
.register-view__card--radio-option:hover {
  background: #FCFCFC;
}
.register-view__card--radio-option p {
  margin: 0;
  padding: 0;
  margin-left: 12px;
}
.register-view__card--radio-option input {
  margin: 0;
  height: 16px;
  width: 16px;
}
.register-view__card--radio-option.radio__other {
  flex-direction: column;
  align-items: unset;
}
.register-view__card--radio-option.radio__other > div {
  display: flex;
  align-items: center;
}
.register-view__card--radio-option.radio__other .base-input {
  margin-top: 16px;
  min-height: 44px;
}
.register-view__discovery-card--actions {
  display: flex;
  gap: 12px;
  margin-top: 32px;
}
</style>
