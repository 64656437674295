
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    text: String,
  }
})
export default class BaseButton extends Vue {
  text!: string;
}
