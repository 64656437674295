<template>
  <div :class="error? 'error' : ''" class="base-field">
    <slot></slot>
    <p v-if="error" class="error">{{ error }}</p>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    error: String,
  }
})
export default class BaseInput extends Vue {
  error!: string;
}
</script>

<style lang="scss">
.base-field {
  display: block;
  padding-top: 6px;
  padding-bottom: 6px;
}
.base-field .error {
  margin-top: 6px;
  margin-bottom: 0;
  color: #BD1212;
}
.base-field.error .base-input {
  border-color: #BD1212;
}
</style>
