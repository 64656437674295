<template>
  <router-view/>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-height: 100%;
}
.base-link {
  text-decoration: unset;
  color: #64B60E;
  transition: ease 0.1s;
  cursor: pointer;
}
.base-link:hover {
  color: #549C08;
  text-decoration: underline;
}
.base-link.external::after {
  position: relative;
  display: inline-block;
  content: url(~@/assets/external.svg);
  margin-left: 6px;
  margin-right: 2px;
  top: -2px;
}
hr {
  border: 0.5px solid #f2f2f2;
}
</style>
