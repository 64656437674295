
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    error: String,
  }
})
export default class BaseInput extends Vue {
  error!: string;
}
