//@ts-nocheck
/* eslint-disable */
import amplitude from 'amplitude-js';

export default {
  setup() {
    //google analytics
    (function(i, s, o, g, r, a, m) {
      i['GoogleAnalyticsObject'] = r;
      (i[r] =
        i[r] ||
        function() {
          (i[r].q = i[r].q || []).push(arguments);
        }),
        (i[r].l = 1 * new Date());
      (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
    ga('create', 'UA-179046835-1', 'auto');
    ga('set', 'checkProtocolTask', function() {});

    //amplitude
    amplitude.getInstance().init('40346f9983a088d57d11fcf93c5fc663', null, {
      includeReferrer: true,
      includeUtm: true,
      saveEvents: true,
      domain: '.ourforest.io',
    });

    this.pageView();
  },
  pageView() {
    const page = window.location.hostname + window.location.pathname;
    ga('send', 'pageview', page);
    amplitude.getInstance().logEvent('pageView', { page: page });
  },
  signUp(email: string) {
    ga('send', 'signup', email);
    amplitude.getInstance().logEvent('signUp', { email: email });
  },
  login(result: string, origin: string, details: string) {
    ga('send', 'login', result, origin, details);
    const detailsLabel = result === 'success'
      ? 'user'
      : 'error'
    amplitude.getInstance().logEvent('Login', {
      result: result,
      origin: origin,
      [`${detailsLabel}`]: details
    });
  },
  setUser(user: User) {
    amplitude.getInstance().setUserId(user === null ? null : user.email);
  },
  referredBy(url: string) {
    ga('send', 'referredBy', url);
    amplitude.getInstance().logEvent('referredBy', { url: url });
  },
  copiedReferralLink(url: string) {
    window.ga('send', 'copiedReferralLink', url);
    amplitude.getInstance().logEvent('copiedReferralLink', { url: url, extension_version: CODE_VERSION });
  },
  clickedLink(url: string) {
    ga('send', 'clickedLink', url);
    amplitude.getInstance().logEvent('clickedLink', { url: url });
  }
};
