
import { Options, Vue } from 'vue-class-component';
import BaseCard from '@/components/BaseCard.vue';
import BaseInput from '@/components/BaseInput.vue';
import BaseField from '@/components/BaseField.vue';
import Confetti from '../components/Confetti.vue';
import BaseButton from '@/components/BaseButton.vue';
import Analytics from '@/helpers/analytics';
import { emitLogin } from '@/helpers/helpers';

@Options({
  title: 'Sign Up',
  components: {
    BaseCard,
    BaseInput,
    BaseField,
    BaseButton,
    Confetti,
  },
  data() {
    return {
      email: '',
      password: '',
      formErrors: {
        email: '',
        password1: '',
        password2: '',
      },
      referredBy: '',
      discoveryOptions: ['Reddit', 'Instagram', 'Facebook', 'Through a friend', 'Blog or publication', 'Employer'],
      radioOption: '',
      otherReason: '',
      hideDiscoveryQuestion: true,
      userToken: null,
    }
  },
  methods: {
    registerUser() {
      
      for (const i in this.formErrors) this.formErrors[i] = '';

      const referredBy = () => {
        if (this.referredBy) {
          return this.referredBy;
        }
        else if (window.localStorage.getItem('lastReferrer')) {
          return window.localStorage.getItem('lastReferrer');
        }
        else {
          return '';
        }
      }

      const formdata = new FormData();
      formdata.append("email", this.email);
      formdata.append("password1", this.password);
      formdata.append("password2", this.password);
      formdata.append("referred_by", referredBy());
      formdata.append("source", "EXTENSION");

      fetch("https://ourearth.io/api/auth/register/", {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      }).then((response: any) => {
        if (response.status === 409) {
          this.formErrors.email = 'This email is already in use.';
        }
        else return response.json();
      }).then((result: any) => {
      if (result) {
        if (result.token) {
          Analytics.setUser(result.user);
          Analytics.signUp(result.user.email);
          emitLogin(result);
          this.userToken = result.token;
          const userObj = JSON.stringify({ token: result.token, user: { email: result.user.email }});
          window.localStorage.setItem("user", userObj);
          if (!this.referredBy) {
            this.hideDiscoveryQuestion = false;
          } else {
            window.location.href = 'https://chrome.google.com/webstore/detail/ourforest-%E2%80%94-automatically/cjgphmckkkgnlkbdaellanfoajipefnd';
          }
        }
        else {
          for (const i in result) this.formErrors[i] = result[i][0];
        }
      }
      })
    },
    skipDiscoveryQuestion() {
      this.$router.push({ name: 'LoadingTree' });
    },
    submitDiscoverAnswer() {
      let reason = '';
      if (this.radioOption === 'Other' && this.otherReason !== '') {
        reason = this.otherReason;
      } else {
        reason = this.radioOption;
      }
      const raw = JSON.stringify({ "how_discovered_us": reason });
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Access ${this.userToken}`);

      fetch("https://ourearth.io/api/user/how_discovered_us/", {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      })
      .then(this.$router.push({ name: 'LoadingTree' }))
    },
    shuffleArray(array: any) {
      for (var i = array.length - 1; i > 0; i--) {
          var j = Math.floor(Math.random() * (i + 1));
          var temp = array[i];
          array[i] = array[j];
          array[j] = temp;
      }
      this.discoveryOptions = array;
    }
  },
  computed: {
    passwordError() {
      let error = '';
      if (this.formErrors.password1) error = this.formErrors.password1;
      if (this.formErrors.password2) error = this.formErrors.password2;
      return error;
    }
  },
  mounted() {
    if (this.$route.query.referrer) {
      this.referredBy = this.$route.query.referrer;
      window.localStorage.setItem("lastReferrer", this.$route.query.referrer);
      Analytics.referredBy(this.referredBy);
    }
    this.shuffleArray(this.discoveryOptions);
  }
})
export default class Register extends Vue {

}
