<template>
  <div class="base-card">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({

})
export default class BaseCard extends Vue {}
</script>

<style lang="scss">
.base-card {
  position: relative;
  background: #FFFFFF;
  border: 0.5px solid #DEE1E6;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 24px;
  width: 100%;
  overflow: hidden;
}
.base-card {
  .title {
    margin: 0;
    padding: 0;
  }
  .mb-2 {
    margin-bottom: 16px;
  }
  .mb-3 {
    margin-bottom: 32px;
  }
  .description {
    margin-top: 12px;
  }
  .header {
    font-size: 80px;
    font-weight: bold;
    padding: 0;
    margin: 0;
  }
}
</style>
