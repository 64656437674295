<template>
  <a class="base-button">
    <div>{{ text }}</div>
  </a>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    text: String,
  }
})
export default class BaseButton extends Vue {
  text!: string;
}
</script>

<style lang="scss">
.base-button {
  display: flex;
  flex: 1;
  height: 48px;
  background-color: #64B60E;
  box-sizing: border-box;
  border-radius: 3px;
  color: #FFFFFF;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  user-select: none;
  cursor: pointer;
  text-decoration: none !important;
  transition: 0.3s ease;
}
.base-button:hover {
  background-color: #6DC70F;
  color: #FFFFFF;
}
.base-button.disabled {
  background-color: #E0E0E0;
  color: #828282;
  cursor: not-allowed;
}
.base-button.secondary {
  background-color: #ffffff;
  border: 1px solid #c2c2c2;
  color: #181818;
}
.base-button.secondary:hover {
  color: #181818;
  background-color: #fafafa;
}
</style>
