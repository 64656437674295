<template>
  <div class="base-input">
    <input
      ref="input"
      v-bind="$attrs"
      :value="modelValue"
      :type="type"
      @input="$emit('update:modelValue', $event.target.value)"
      :placeholder="placeholder">
    <div class="base-input__visibility" v-if="type === 'password'" @click="toggleVisibility">
      <img v-if="isTextVisible" src="../assets/hide.svg" />
      <img v-else src="../assets/reveal.svg" />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    placeholder: String,
    modelValue: String,
    type: {
      type: String,
      default: 'text'
    },
  },
  data() {
    return {
      isTextVisible: false,
    }
  },
  methods: {
    toggleVisibility() {
      this.isTextVisible = !this.isTextVisible;
      if (this.isTextVisible) this.$refs.input.type = 'text';
      else this.$refs.input.type = 'password';
    },
  }
})
export default class BaseInput extends Vue {
  placeholder!: string;
  modelValue!: string;
  type!: string;
}
</script>

<style lang="scss">
.base-input {
  display: flex;
  flex: 1;
  height: 48px;
  background: #FFFFFF;
  border: 1px solid #DBDBDB;
  box-sizing: border-box;
  border-radius: 3px;
  overflow: hidden;
  padding-left: 8px;
  padding-right: 8px;
}
.base-input input {
  flex: 1;
  outline: 0;
  border: 0;
  font-size: 16px;
  font-family: inherit;
}
.base-input__visibility {
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    filter: brightness(1.1);
  }
}
</style>
